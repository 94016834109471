@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
$header-height-desktop: 72px !default;
$header-height-mobile: 64px !default;
$header-background-color: white !default;
$header-text-color: $black !default;
$header-box-shadow: unset !default;
$header-text-color: $black !default;

.header {
	$this: &;

	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 16px;
	height: $header-height-mobile;
	background: $header-background-color;
	box-sizing: border-box;
	border-bottom: 1px solid var(--color-header-bottom-border, $header-border-bottom);
	position: relative;
	z-index: 5;

	@include gridle_state(md) {
		padding: 0 48px;
		height: $header-height-desktop;
		z-index: 4; // to be above sdp filters
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1 auto;
	}

	&__back {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;

		@include gridle_state(md) {
			margin-right: 31px;
		}

		svg {
			color: $header-text-color;
		}
	}

	&__credit {
		margin-left: 20px;
		margin-right: 24px;

		@include gridle_state(md) {
			margin-left: 35px;
			margin-right: 35px;
		}

		.relative-link__text {
			display: flex;
			flex-direction: column;
			align-items: center;
			cursor: pointer;

			svg {
				height: 27px;
				width: 27px;
				color: $header-text-color;

				@include gridle_state(md) {
					height: 30px;
					width: 30px;
				}
			}

			.amount {
				margin-top: 8px;
				color: $header-text-color;
			}
		}

		&-label {
			margin-top: 8px;
			color: $header-text-color;
		}
	}

	.account-menu {
		position: relative; // sinon le libelle du picto ne s'affiche pas
		margin-left: auto;
	}

	.shop-switcher {
		margin-right: 24px;

		&__current-shop {
			color: $header-text-color;
		}

		&__button {
			svg {
				color: $header-text-color;
			}
		}

		@include gridle_state(md) {
			margin-right: 35px;
		}
	}

	&__phone {
		svg {
			color: $header-text-color;
		}

		&-button {
			display: flex;
			flex-direction: column;
			text-decoration: unset;
			align-items: center;
			color: $header-text-color;
		}
	}

	&__operator {
		position: fixed;
		top: 15px;
		right: 40px;
		color: $black;
		padding: 5px 10px;
		border: 1px solid $grey-medium;
		background: $grey-light;
		border-radius: $border-radius-small;
		z-index: 10;
	}

	&__logo {
		display: flex;
		align-items: center;

		.logo-brand__logo {
			height: $header-height-mobile;

			@include gridle_state(md) {
				height: $header-height-desktop;
			}
		}

		// Custom logo EK
		&--eh {
			align-items: normal;
			height: 100%;
			margin-left: 0;
			z-index: 3;

			.logo-brand__logo {
				width: 75px;
				height: 105px;

				@include gridle_state(md) {
					transform: unset;
					height: 125px;
					width: 90px;
				}
			}

			.relative-link,
			.relative-link__text {
				display: block;
			}

			&-small {
				.logo-brand__logo {
					width: 62px;
					transform: translateY(-32px);

					@include gridle_state(md) {
						width: 70px;
						transform: translateY(-39px);
					}
				}
			}
		}

		&--disabled {
			pointer-events: none;
		}
	}

	&--transparent {
		background: transparent;
		box-shadow: none;
		border: none;

		.header__credit {
			svg,
			.amount,
			&-label {
				color: white;
			}
		}

		.hamburger-account {
			svg,
			&__label {
				color: white;
			}
		}
	}
}
